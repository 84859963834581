import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import {Grid, Button} from '@mui/material';


function App() {
  const [data, setData] = useState([]);
  const [minVakit, setMinVakit] = useState("");
  const vakitler = [
    {name: 'sabah', label: 'Sabah', vakitIndex: 0},
    {name: 'ogle', label: 'Öğle', vakitIndex: 1},
    {name: 'ikindi', label: 'İkindi', vakitIndex: 2},
    {name: 'aksam', label: 'Akşam', vakitIndex: 3},
    {name: 'yatsi', label: 'Yatsı', vakitIndex: 4},
    {name: 'vitr', label: 'Vitr', vakitIndex: 5},
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const updateData = async (vakit, type="up") => {
    const newData = [...data];
    const vakitIndex = vakitler.findIndex(v => v.vakitIndex === vakit);
    if (type === "up") {
      newData[vakitIndex].value = parseInt(newData[vakitIndex].value) + 1;
    } else {
      newData[vakitIndex].value = parseInt(newData[vakitIndex].value) - 1;
    }
    setData(newData);

    const payload = {
      vakit: vakit,
      isUp: type === "up"
    };
    await axios.post(`${process.env.REACT_APP_API_URL}/?p=updown`, payload);
  };

  const fetchData = async () => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/?p=get`);
    const resultPayload = result.data;
    const vakitData = vakitler.map((vakit) => {
      return {
        name: vakit.name,
        label: vakit.label,
        vakitIndex: vakit.vakitIndex,
        value: resultPayload[vakit.vakitIndex],
      };
    });
    setData(vakitData);
    // Find min value of vakitData
    const min = vakitData.reduce((min, p) => p.value < min ? p.value : min, vakitData[0].value);
    const minVakitData = vakitData.find(v => v.value === min);
    setMinVakit(minVakitData.label);
  };
  return (
    <div className="App">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Minimum is: <span style={{color: "#fc4903"}}>{minVakit}</span></h3>
        </Grid>
        {data.map((vakit) => (
        <Grid item xs={12} md={2} key={vakit.name + vakit.vakitIndex} style={{marginBottom: "15px"}} className="grid">
          <h2>{vakit.label}</h2>
          <Button variant="contained" className="button" color="error" onClick={() => updateData(vakit.vakitIndex, "down")}>-</Button>
          <span className="value" style={{marginRight: "20px", marginLeft: "15px", fontSize: "3rem"}}>{vakit.value % 365}</span>
          <Button variant="contained" className="button" color="success" onClick={() => updateData(vakit.vakitIndex, "up")}>+</Button>
          <h3>{(vakit.value - (vakit.value % 365)) / 365 } yıl</h3>
        </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default App;
